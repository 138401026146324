import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addClassMember,
  createLesson,
  getClass,
  getClassMembers,
  getLessons,
} from "../../utils/apiCallHandler";
import { actionLoadingStates } from "../../utils/stateEnums";

const { idle, pending, succeeded, failed } = actionLoadingStates;

export const initialState = {
  members: [],
  lessons: [],
  loading: idle,
  loadingLessons: idle,
  loadingMembers: idle,
  justLoaded: false,
  justLoadedLessons: false,
  createLessonStatus: idle,
};

export const setClassId = (state, action) => {
  if (state.id !== action.payload) {
    Object.assign(state, {
      ...initialState,
      id: action.payload,
    });
  }
};

export const loadClass = createAsyncThunk(
  `class/load`,
  async ({ classId, schoolId, accessToken }) => {
    const response = await getClass(classId, schoolId, accessToken);
    return response?.data;
  },
);

export const addNewClassMember = createAsyncThunk(
  `class/member/add`,
  async ({ userId, classId, schoolId, accessToken, type }) => {
    const response = await addClassMember(
      userId,
      classId,
      schoolId,
      accessToken,
      type,
    );
    return response?.data;
  },
);

export const loadClassMembers = createAsyncThunk(
  `class/loadMembers`,
  async ({ classId, schoolId, accessToken }) => {
    const response = await getClassMembers(classId, schoolId, accessToken);
    return response?.data;
  },
);

export const createNewLesson = createAsyncThunk(
  `class/createLesson`,
  async ({ lesson, accessToken }) => {
    const response = await createLesson(lesson, accessToken);
    return response?.data;
  },
);

export const loadLessons = createAsyncThunk(
  `class/loadLessons`,
  async ({ classId, accessToken }) => {
    const response = await getLessons(classId, accessToken);

    return response?.data;
  },
);

const expireJustLoaded = (state) => {
  state.justLoaded = false;
};

export const extraReducers = (builder) => {
  builder.addCase("class/load/pending", (state) => {
    return { ...state, loading: pending };
  });
  builder.addCase("class/load/fulfilled", (state, action) => {
    return {
      ...state,
      ...action.payload,
      loading: succeeded,
      justLoaded: true,
    };
  });
  builder.addCase("class/load/rejected", (state, action) => {
    return { ...state, error: action.error, loading: failed };
  });
  builder.addCase("class/member/add/fulfilled", (state, action) => {
    state.members.push(action.payload.class_member);
  });
  builder.addCase("class/loadMembers/pending", (state) => {
    return { ...state, loadingMembers: pending };
  });
  builder.addCase("class/loadMembers/fulfilled", (state, action) => {
    state.members = action.payload;
    state.loadingMembers = succeeded;
  });
  builder.addCase("class/loadLessons/pending", (state) => {
    return { ...state, loadingLessons: pending };
  });
  builder.addCase("class/loadLessons/fulfilled", (state, action) => {
    state.lessons = action.payload;
    state.loadingLessons = succeeded;
    state.justLoadedLessons = true;
  });
  builder.addCase("class/loadLessons/rejected", (state, action) => {
    return {
      ...state,
      error: action.error,
      loadingLessons: failed,
    };
  });
  builder.addCase("class/createLesson/pending", (state) => {
    return { ...state, createLessonStatus: pending };
  });
  builder.addCase("class/createLesson/fulfilled", (state, action) => {
    state.lessons = [action.payload];
    state.createLessonStatus = succeeded;
  });
  builder.addCase("class/createLesson/rejected", (state, action) => {
    return {
      ...state,
      error: action.error,
      createLessonStatus: failed,
    };
  });
};

export const reducers = {
  setClassId,
  expireJustLoaded,
};
