import { createSlice } from "@reduxjs/toolkit";
import {
  reducers,
  extraReducers,
  initialState,
} from "./reducers/classReducers";

export const ClassSlice = createSlice({
  name: "class",
  initialState,
  reducers,
  extraReducers,
});

export const { setClassId, expireJustLoaded } = ClassSlice.actions;
export default ClassSlice.reducer;
