export const getUserRoles = (school) => {
  if (!school || !school.roles) return [];

  return school.roles;
};

export const isSchoolOwner = (userRoles) => {
  return userRoles.includes("owner");
};

export const isSchoolTeacher = (userRoles) => {
  return userRoles.includes("teacher");
};

export const isSchoolStudent = (userRoles) => {
  return userRoles.includes("student");
};

export const isSchoolTeacherOrOwner = (userRoles) => {
  return isSchoolOwner(userRoles) || isSchoolTeacher(userRoles);
};

export const isClassTeacher = (userRoles, user, schoolClass) => {
  return (
    !!user?.profile?.user &&
    isSchoolTeacher(userRoles) &&
    schoolClass.teachers
      ?.map((teacher) => teacher.id)
      .includes(user.profile.user)
  );
};
