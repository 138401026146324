import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import "../../assets/stylesheets/Footer.scss";

import "material-symbols";

const Footer = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const school = useSelector((state) => state.school);
  const schoolIsVerified = school && school.verified_at;

  const location = useLocation();
  // Hide footer if the location pathname matches /{locale}/projects/{identifier}, /{locale}/embed/viewer/{identifier} or /{locale}/school/project
  const locale = "[a-z]{2}(-[A-Z]{2})?";
  const hideFooter = location.pathname.match(
    new RegExp(
      `/${locale}/projects/[a-z0-9-]+|/${locale}/school/project|/${locale}/embed/viewer/[a-z0-9-]+`,
    ),
  );
  if (hideFooter) {
    return;
  }

  return (
    <footer className="footer">
      <div className={`footer__links-wrapper`}>
        <div
          className={`footer__top ${
            auth.user && schoolIsVerified ? "" : "reverse"
          }`}
        >
          {auth.user && schoolIsVerified ? (
            <>
              <p className="footer__report">
                <span className="material-symbols-outlined">flag</span>
                <p className="footer__text">
                  {t("footer.safeguardingConcern")}{" "}
                </p>
                <a
                  className="footer__link"
                  href={`https://form.raspberrypi.org/f/report-concern-code-editor-for-education?tfa_2019=${auth.user?.profile?.user}&tfa_2021=${school.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("footer.contactUs")}
                </a>
              </p>
            </>
          ) : (
            <p className="footer__text">{t("footer.charityNameAndNumber")}</p>
          )}

          <ul className="footer_links">
            <li>
              <a
                className="footer__link"
                href="https://help.editor.raspberrypi.org/hc/en-us"
                target="_blank"
                rel="noreferrer"
              >
                {t("footer.help")}
              </a>
            </li>
            <li>
              <a
                className="footer__link"
                href="https://my.raspberrypi.org/code-editor-for-education-terms/"
                target="_blank"
                rel="noreferrer"
              >
                {t("footer.termsAndConditions")}
              </a>
            </li>
            <li>
              <a
                className="footer__link"
                href="https://www.raspberrypi.org/safeguarding/"
                target="_blank"
                rel="noreferrer"
              >
                {t("footer.safeguarding")}
              </a>
            </li>
            <li>
              <a
                className="footer__link"
                href="https://www.raspberrypi.org/accessibility/"
                target="_blank"
                rel="noreferrer"
              >
                {t("footer.accessibility")}
              </a>
            </li>
            <li>
              <a
                className="footer__link"
                href="https://www.raspberrypi.org/privacy/"
                target="_blank"
                rel="noreferrer"
              >
                {t("footer.privacy")}
              </a>
            </li>
            <li>
              <a
                className="footer__link"
                href="https://www.raspberrypi.org/cookies/"
                target="_blank"
                rel="noreferrer"
              >
                {t("footer.cookies")}
              </a>
            </li>
          </ul>
        </div>
        {auth.user && schoolIsVerified && (
          <div>
            <p className="footer__text">{t("footer.charityNameAndNumber")}</p>
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
